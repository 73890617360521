export const fallbackLng = "th";
export const languages = [fallbackLng, 'en'] as const;
export const defaultNS = "translation";
export const cookieName = "i18next";

export function getOptions(lng = fallbackLng, ns = defaultNS) {
    return {
        // debug: true,
        supportedLngs: languages,
        fallbackLng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
    };
}

export type Language = (typeof languages)[number];
export type LocaleAttribute = "TH" | "EN";
// export type LanguageOptions = {
//     supportedLngs: Language[];
//     defaultLanguage: Language;
//     langauge: Language;
//     fallbackNS: string;
//     defaultNS: string;
//     ns: string;
// };
