"use client";

import {
    addUserFavoriteProduct,
    deleteUserFavoriteProduct,
    getUser,
    getUserAddresses,
    getUserFavoriteProductIds,
    getUserFavoriteProducts,
} from "@/actions/user";
import { createAddress } from "@/actions/user.address";
import { CreateUserAddressData, IUserAddress } from "@/util/query/user-address-query";
import { IUserProfile } from "@/util/query/user-query";
import { createContext, useContext, useEffect, useState } from "react";
import { IUserFavorite } from "../../util/query/user-query";
import { useSession } from "next-auth/react";
import { Constant } from "@/constants/constants";

interface ProfileContextData {
    transaction: boolean;
    statusLoading: boolean;
    favoriteIds: string[];
    triggerUpdateProfile: number;
    assignUserAddress: (data: CreateUserAddressData) => Promise<string>;
    getProfile: () => Promise<IUserProfile | null>;
    getAddresses: () => Promise<IUserAddress[]>;
    getFavorites: (page: number, pageLimit: number) => Promise<IUserFavorite[]>;
    addFavorite: (productHandle: string) => Promise<void>;
    removeFavorite: (productHandle: string) => Promise<void>;
    isFavorite: (productHandle: string) => boolean;
    setTriggerUpdateProfile: (timeInterval: number) => void;
}

const ProfileContext = createContext<ProfileContextData>({
    transaction: false,
    statusLoading: false,
    favoriteIds: [],
    triggerUpdateProfile: 0,
    assignUserAddress: async (data: CreateUserAddressData) => "",
    getProfile: async () => null,
    getAddresses: async () => [],
    getFavorites: async (page: number, pageLimit: number) => [],
    addFavorite: async (productHandle: string) => {},
    removeFavorite: async (productHandle: string) => {},
    isFavorite: (productHandle: string) => false,
    setTriggerUpdateProfile: (timeInterval: number) => {},
});

export interface ProfileProviderProps {
    children: React.ReactNode;
}

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
    const [statusLoading, setStatusLoading] = useState<boolean>(true);
    const [transaction, setTransaction] = useState<boolean>(false);
    const [favoriteIds, setFavoriteIds] = useState<string[]>([]);
    const [triggerUpdateProfile, setTriggerUpdateProfile] = useState<number>(0);

    const session = useSession();

    const assignUserAddress = async (data: CreateUserAddressData) => {
        setTransaction(true);
        const address = await createAddress(data);
        setTransaction(false);
        return address.id;
    };

    const getProfile = async () => {
        const profile = await getUser();
        return profile;
    };

    const getAddresses = async () => {
        const addresses = await getUserAddresses();
        return addresses;
    };

    const getFavorites = async (page: number, pageLimit: number = Constant.pageLimit) => {
        const fav = await getUserFavoriteProducts(page, pageLimit);
        return fav;
    };

    const getFavoriteIds = async () => {
        const favIds = await getUserFavoriteProductIds();
        setFavoriteIds(favIds);
    };

    const addFavorite = async (productHandle: string) => {
        const fav = await addUserFavoriteProduct(productHandle);
        if (fav) {
            setFavoriteIds((prev) => [...prev, fav.msProductHandle]);
        }
    };

    const removeFavorite = async (productHandle: string) => {
        await deleteUserFavoriteProduct(productHandle);
        const favIds = favoriteIds.filter((i) => i !== productHandle);
        setFavoriteIds(favIds);
    };

    const isFavorite = (productHandle: string) => {
        return !!favoriteIds.find((i) => i === productHandle);
    };

    useEffect(() => {
        if (session.status !== "loading") {
            setStatusLoading(false);
            setFavoriteIds([]);
            getFavoriteIds();
        } else {
            setStatusLoading(true);
        }
    }, [session.status]);

    return (
        <ProfileContext.Provider
            value={{
                transaction,
                favoriteIds,
                statusLoading,
                triggerUpdateProfile,
                assignUserAddress,
                getProfile,
                getAddresses,
                getFavorites,
                addFavorite,
                removeFavorite,
                isFavorite,
                setTriggerUpdateProfile
            }}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfile = () => {
    return useContext(ProfileContext);
};
