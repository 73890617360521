"use client";

import { createContext, useContext, useEffect, useState } from "react";



interface SystemConfigContextData {
    canPurchase: boolean;
}

const SystemConfigContext = createContext<SystemConfigContextData>({
    canPurchase: false,
});

export interface SystemConfigProviderProps {
    children: React.ReactNode;
}

export const SystemConfigProvider = ({ children }: SystemConfigProviderProps) => {
    
    const [canPurchase, setCanPurchase] = useState<boolean>(true);

    useEffect(() => {
        
    }, []);

    return (
        <SystemConfigContext.Provider
            value={{
                canPurchase,                
            }}>
            {children}
        </SystemConfigContext.Provider>
    );
};

export const useSystemConfig = () => {
    return useContext(SystemConfigContext);
};
